@import '../base/variables';
@import '../tools/mixins';
@import '../../../../../../../node_modules/bootstrap/scss/mixins/breakpoints';


// Page d'accueil

body.home {
  section.block-slider {
    margin-bottom: 80px;
  }
}